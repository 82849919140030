@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/br-firma');

@layer base {
	body {
		@apply font-firma tracking-tight;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a,
	span,
	div,
	li,
	button {
		@apply font-firma tracking-tight;
	}
}

@layer utilities {
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

body {
	margin: 0;
	font-family: 'firma', sans-serif;
	font-optical-sizing: auto;
}

@layer base {
	body {
		@apply box-border scroll-smooth;
	}
}

.ant-modal-content {
	font-family: 'firma', sans-serif;
	font-optical-sizing: auto;
}

.ant-drawer-content-wrapper {
	font-family: 'firma', sans-serif;
	font-optical-sizing: auto;
}

.skeleton {
	background: #f0f0f0;
	height: 50px;
	width: 100%;
	border-radius: 4px;
	margin: 5px 0;
}

.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Remove spinner for Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Remove spinner for Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
